 <!-- footer -->

 <footer class="it-footer">
  <div class="it-footer-main">
    <div class="container">
      <section>
        <div class="row clearfix">
          <div class="col-sm-12">
            <div class="it-brand-wrapper">
              <a class="" href="#">
                <img alt=""
                     class="img-fluid"
                     src="https://italia.github.io/bootstrap-italia/docs/assets/img/icons/logo-agid.svg" width="200px">
                <div class="it-brand-text" style="margin-left: 10px;">
                  <img alt=""
                       class="img-fluid" src="https://italia.github.io/bootstrap-italia/docs/assets/img/icons/logo-team-digitale-full.svg" width="200px">
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="py-4 border-white border-top">
        <div class="row">
          <div class="col-lg-4 col-md-4 pb-2">
            <h4>
              <a href="#" title="Vai alla pagina: Contatti">Contatti</a>
            </h4>
            <p>
              <strong>Conversano</strong>
              <br>Indirizzo: Vico della Vittoria, 24 Puglia - Italia <br> P. IVA: 05512020727 <br>
              Telefono: 080 495 96 06
            </p>
            <div class="link-list-wrapper">
              <ul class="footer-list link-list clearfix">
                <li>
                  <a class="list-item" href="#"
                     title="Vai alla pagina: Posta Elettronica Certificata">
                    Posta
                    Elettronica
                    Certificata: gestioneservizispa@pec.it
                  </a>
                </li>
                <li>
                  <a class="list-item" href="#" title="Email">
                    Email:
                    itsupport@gestioneservizispa.it
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 pb-2">
            <h4>
              <a routerlink="/integrazioni" title="Vai alla pagina: Integrazioni">Assistenza</a>
            </h4>
            <div class="link-list-wrapper">
              <ul class="footer-list link-list clearfix">
                <li>
                  <a class="list-item" href="#" title="Vai alla pagina: FAQ">FAQ</a>
                </li>
                <li>
                  <a class="list-item" href="#"
                     title="Vai alla pagina: Aggiornamento">Aggiornamento</a>
                </li>
                <li>
                  <a class="list-item" href="#" title="Vai alla pagina: Assistenza">Assistenza</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 pb-2">
            <h4>
              <a routerlink="/integrazioni" title="Vai alla pagina: Servizi ">Servizi</a>
            </h4>
            <div class="link-list-wrapper">
              <ul class="footer-list link-list clearfix">
                <li>
                  <a class="list-item" href="#" title="Vai alla pagina: Pagamenti">Pagamenti</a>
                </li>
                <li>
                  <a class="list-item" href="#" title="Vai alla pagina: Sostegno">Sostegno</a>
                </li>
                <li>
                  <a class="list-item" href="#" title="Vai alla pagina: Segnalazioni">Segnalazioni</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 pb-2">
            <div class="pb-2">
              <h4>
                <a href="#" title="Vai alla pagina: Seguici su">Novità</a>
              </h4>
              <div class="link-list-wrapper">
                <ul class="footer-list link-list clearfix">
                  <li>
                    <a class="list-item" href="#" title="Vai alla pagina: Notizie">Notizie</a>
                  </li>
                  <li>
                    <a class="list-item" href="#" title="Vai alla pagina: Eventi">Eventi</a>
                  </li>
                  <li>
                    <a class="list-item" href="#" title="Vai alla pagina: Comunicati">Comunicati</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 pb-2">
            <div class="pb-2">
              <h4>
                <a href="#" title="Vai alla pagina: Seguici su">Link esterni</a>
              </h4>
              <div class="link-list-wrapper">
                <ul class="footer-list link-list clearfix">
                  <li>
                    <a class="list-item" href="https://autorizzo.it" target="_blank"
                       title="Vai alla pagina: Autorizzo">Autorizzo</a>
                  </li>
                  <li>
                    <a class="list-item" href="https://sanzioni.net" target="_blank"
                       title="Vai alla pagina: Sanzioni">Sanzioni</a>
                  </li>
                  <li>
                    <a class="list-item" href="#" title="Vai alla pagina: Portale del cittadino">
                      Portale
                      del
                      cittadino
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <div class="it-footer-small-prints clearfix" style="background-color: #455B71;">
    <div class="container">
      <div class="row">
        <ul class="it-footer-small-prints-list col-md-4 list-inline mb-0 d-flex flex-column flex-md-row">
          <li class="list-inline-item">
            <a href="#" title="Note Legali">Privacy policy</a>
          </li>
          <li class="list-inline-item">
            <a href="#" title="Note Legali">Cookies</a>
          </li>
        </ul>
        <ul class="it-footer-small-prints-list col-md-4 list-inline mb-0 d-flex flex-column flex-md-row">
          <li class="list-inline-item">
            <a href="https://gestioneservizispa.it" title="Note Legali">
              Powered by <strong>
                Gestione Servizi
                Spa
              </strong>
            </a>
          </li>
        </ul>
        <ul class="it-footer-small-prints-list col-md-4 list-inline mb-0 text-right">
          <li class="list-inline-item">
            <a href="#" title="Note Legali">@{{year}} - All rights reserved.</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>

