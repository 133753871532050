import { HomePageComponent } from './home-page/home-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';






const routes: Routes = [

  { path: '', loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePageModule)},
  { path: 'raccolta', loadChildren: () => import('./raccolta/raccolta.module').then(m => m.RaccoltaModule)},
  { path: 'tariffazione', loadChildren: () => import('./tariffazione/tariffazione.module').then(m => m.TariffazioneModule)},
  { path: 'cosa-fare', loadChildren: () => import('./cosa-fare/cosa-fare.module').then(m => m.CosaFareModule)},
  { path: 'reclami', loadChildren: () => import('./reclami/reclami.module').then(m => m.ReclamiModule)},
  { path: 'comunicazioni-arera', loadChildren: () => import('./comunicazioni-arera/comunicazioni-arera.module').then(m => m.ComunicazioniAreraModule)},
  { path: 'mappe', loadChildren: () => import('./mappe/mappe.module').then(m => m.MappeModule)},
  { path: 'faq', loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule)},
  { path: 'contatti', loadChildren: () => import('./contatti/contatti.module').then(m => m.ContattiModule)},
  { path: '**', redirectTo: '', pathMatch: 'full'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
