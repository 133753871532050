import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BaseComponent } from './base.component';
import { BaseRoutingModule } from './base-routing.module';




@NgModule({
  declarations: [
    BaseComponent,
    HeaderComponent,
    FooterComponent,
  ],
  imports: [
    CommonModule,
    BaseRoutingModule
  ],
  exports: [
    BaseComponent,
    HeaderComponent,
    FooterComponent,
  ]
})
export class BaseModule { }
